import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

createApp(App)
    .use(router)
    // .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')

import "bootstrap/dist/js/bootstrap.js"
