import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/verified',
    name: 'Verifizierte JnRs',
    component: () => import('../views/SearchVerified.vue')
  },
  {
    path: '/jnr/:id',
    name: 'JnR',
    component: () => import('../views/JnrOverview.vue')
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import('../views/Tutorial.vue')
  },
  {
    path: '/adv',
    name: 'Adventskalender',
    component: () => import('../views/Adv.vue')
  },
  {
    path: '/astpositions',
    name: 'Armorstand Positionen',
    component: () => import('../views/AstPositions.vue')
  },
  {
    path: '/commands/jumpworld',
    name: 'JumpWorld Befehle',
    component: () => import('../views/CommandsJumpworld.vue')
  },
  {
    path: '/commands/worldedit',
    name: 'JumpWorld WorldEdit Befehle',
    component: () => import('../views/CommandsWorldedit.vue')
  },
  {
    path: '/events',
    name: 'Events Overview',
    component: () => import('../views/EventsOverview.vue')
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: () => import('../views/Event.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active"
})

export default router
