<template>
  <div v-bind:class="activeClass" class="h-100">
    <Navbar/>
    <div class="container-fluid mt-2">
      <router-view></router-view>
    </div>
  </div>
</template>

<style >
  html { height: 100%; overflow:auto; }
  body { height: 100%;}
  @media (prefers-color-scheme: dark) {
    body {
      background: #121212;
    }
  }
  .bgActive:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    filter: blur(4px);
    background-image: url('~@/assets/background.png');
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>

<script>
import Navbar from "@/components/Navbar";

export default {
  components: {Navbar},
  data() {
    return {
      activeClass: ''
    }
  },
  created() {
    this.checkRoute(this.$route);
  },
  watch:{
    $route (to, from) {
      this.checkRoute(to);
    }
  },
  methods: {
    checkRoute(route) {
      this.activeClass = ''
      if (route.name === 'Home') {
        this.activeClass = 'bgActive'
      }
    }
  }
}
</script>
