<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="nav-link text-white" to="/home">
        <img alt="" class="d-inline-block align-text-top" src="https://www.timolia.de/media/logo.png" width="22">
        Timolia
      </router-link>
      <button aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
              data-bs-target="#navbarNav" data-bs-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarNav" class="collapse navbar-collapse">
        <ul class="navbar-nav w-100">
          <li class="nav-item">
            <router-link class="nav-link" to="/home">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/search">Suche</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/verified">Verifizierte JnRs</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/events">Events</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/tutorial">Tutorial</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link class="nav-link" to="/adv">Adventskalender</router-link>-->
<!--          </li>-->
        </ul>
        <ul class="navbar-nav ml-auto w-100 justify-content-end">
          <li class="nav-item">
            <a class="nav-link" href="https://www.timolia.de/imprint">Impressum</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.timolia.de/privacypolicy">Datenschutzerklärung</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.timolia.de/terms">Nutzungsbedingungen</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Navbar'
}
</script>
<style>
.navbar-dark .navbar-nav .nav-link.active {
  color: #f39c12;
}
</style>
